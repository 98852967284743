.or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

.or-text {
  white-space: nowrap;
  margin: 0;
}
