.sidebar {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
  width: 100%;
  border-right: 1px solid #e0e0e0;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 1em;
  background-color: white;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-element {
  position: relative;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.1s ease;
  border-radius: 0.3em;
  margin-top: 0.3em;
}

.sidebar-element:hover {
  background-color: #e2f3ff;
}

.sidebar-element-active {
    background-color: #a6d9ff;
}

.sidebar-element-active:hover {
  background-color: #7ecaff;
}

.sidebar-element:active {
  animation: bounce 0.1s ease forwards;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.color-dark {
  color: var(--bs-dark);
}
