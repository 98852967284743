.toastify-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    pointer-events: none;
}

.toastify-close-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    color: #d2d2d2;
    transition: color 0.3s;
    pointer-events: auto;
}

.toastify-close-button-container:hover {
    cursor: pointer;
    color: var(--bs-dark);
}
