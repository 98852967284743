.btn-patreon {
  background-color: #f96854;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-patreon:hover {
  background-color: #e5533c;
}

.patreon-icon {
  background-image: url('localhost:3000/public/icons/commercial/patreon.png') no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
