@supports (animation: success-grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
  .success-tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
    animation-delay: .6s;
  }

  .success-circle {
    fill-opacity: 0;
    stroke: #25ae88;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: success-grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
  }
}

@keyframes success-grow {
  60% {
    transform: scale(.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #25ae88;
  }
}

@keyframes draw {
  0%, 100% { stroke-opacity: 1; }
  100% { stroke-dashoffset: 0; }
}
