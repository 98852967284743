.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-glass {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-glass-dark {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: var(--bs-secondary);
  margin: 0 10px;
}

.form-control {
  border: 1px solid darkgrey;
}

.card-lg {
  width: 66vw;
  min-height: 90vh;
}

@media screen and (max-width: 800px) {
  .card-lg {
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

.card-sm {
  width: 480px;
}

.div-list > div {
  margin-bottom: 0.5em;
}

.div-list-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.5em;
}

@media screen and (max-width: 500px) {
  .card-sm {
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

.clickable:hover {
    cursor: pointer;
}

.dropdown-toggle.dropdown-no-icon::after {
  content: none !important;
}
.dropdown-item {
  color: #2a2a2a !important;
}
