.nav-layout-outer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
}

.nav-layout-inner {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav-layout-content-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav-layout-content-inner {
  padding: 2em;
}

.nav-content {
  width: 100%;
}

.sidebar-wrapper {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
}

.sidebar-wrapper.sidebar-open {
  transform: translateX(0);
}

.sidebar-wrapper.sidebar-closed {
  transform: translateX(-100%);
}

.nav-sidebar-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.66);
  transition: opacity 0.3s ease-in;
  z-index: 1;
}

.nav-sidebar-background.sidebar-open {
  opacity: 1;
}

.nav-sidebar-background.sidebar-closed {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 420px) {
  .sidebar-wrapper {
    width: 320px;
  }
}

@media (min-width: 1170px) {
  .nav-sidebar-background.sidebar-open {
    opacity: 0;
    pointer-events: none;
  }
  .sidebar-wrapper {
    width: 320px;
    transform: translateX(0) !important;
  }
  .nav-content {
    margin-left: 340px;
  }
}

@media (min-width: 1440px) {
  .nav-content {
    width: 66%;
  }
}

@media (min-width: 1920px) {
  .nav-content {
    margin-left: 0;
    width: 50%;
  }
}
