.overlay-outer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.overlay-outer.fade-out {
  opacity: 0;
  pointer-events: none;
}

.overlay-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
}
