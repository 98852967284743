.btn-subscribestar {
  background-color: #3e9cbf;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-subscribestar:hover {
  background-color: #337ea2;
}

.subscribestar-icon {
  background: url('localhost:3000/public/icons/commercial/subscribestar.png') no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
