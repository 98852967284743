.navbar-top {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.nav-top-content {
  display: flex;
  justify-content: stretch;
  padding: 0 1em;
  height: 4em;
}

.navbar-top-container-left, .navbar-top-container-right, .navbar-top-container-center {
  display: flex;
  align-items: center;
  width: 1em;
  height: 100%;
  flex-grow: 1;
}

.navbar-top-container-left {
  justify-content: start;
}

.navbar-top-container-center {
  justify-content: center;
  width: 5em;
}

.navbar-top-container-right {
  justify-content: end;
}

.menu-icon > i {
  transition: transform 0.3s ease-in-out;
}


@media (min-width: 1170px) {
  .menu-icon {
    display: none;
  }
}

.rotated {
  transform: rotate(180deg);
}