@supports (animation: failure-grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
  .ft-red-cross {
    width: 100px;
    height: 100px;
  }

  .ft-red-cross .cross {
    stroke-opacity: 0;
    stroke-dasharray: 56.5685px; // Approximate length of one diagonal line (√(16² + 16²)) * 2 for both lines
    stroke-dashoffset: 56.5685px;
    animation: draw 0.5s cubic-bezier(.25, .25, .25, 1) forwards;
    animation-delay: 0.6s;
  }

  .ft-red-cross .circle {
    fill-opacity: 0;
    stroke: #e74c3c; // Red color
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: failure-grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
  }
}

@keyframes failure-grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #e74c3c; // Red fill
  }
}

@keyframes draw {
  0%, 100% { stroke-opacity: 1; }
  100% { stroke-dashoffset: 0; }
}
